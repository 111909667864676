import React, { useEffect, useState, useCallback } from "react";
import "./Profile.css";
import config from "../../config";
import axios from "axios";
import Badges from "../ProfileComponents/Badges";
import ProfileRole from "../ProfileComponents/ProfileRole";
import ProfilePicture from "../ProfileComponents/ProfilePicture"; // Import the new ProfilePicture component
import ProfileBio from "../ProfileComponents/ProfileBio";
import Interests from "../ProfileComponents/Interests";
import { clearLocalStorage } from "../../data/static";
function Profile({ profile, username, setViewingAnalysis, token }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState({
    profile_picture: profile.profile_picture,
    bio: profile.bio,
    interests: profile.interests,
  });
  const [newPicture, setNewPicture] = useState(null);
  const [previewPicture, setPreviewPicture] = useState(profile.profile_picture); // Added preview state
  const [errors, setErrors] = useState({}); // To track validation errors

  clearLocalStorage();

  const handleInputChange = useCallback((e) => {
    setErrors('');
    const { name, value } = e.target;
    setEditedProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleImageChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      const newImageUrl = URL.createObjectURL(file);
      setPreviewPicture(newImageUrl); // Update preview before saving
      setNewPicture(file); // Store the file for uploading
      setErrors((prevErrors) => ({ ...prevErrors, profile_picture: null })); // Clear image error
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        profile_picture: "Please upload a valid image file.",
      }));
    }
  }, []);

  const validateProfile = useCallback(() => {
    const newErrors = {};

    // Check if bio is empty
    if (!editedProfile.bio || editedProfile.bio.trim().length === 0) {
      newErrors.bio = "Bio is required.";
    }

    // Check if interests is empty
    if (
      !editedProfile.interests ||
      editedProfile.interests.trim().length === 0
    ) {
      newErrors.interests = "Interests are required.";
    }

    // Validate image size (optional, 2MB max for example)
    if (newPicture && newPicture.size > 2 * 1024 * 1024) {
      newErrors.profile_picture = "Image file size must be under 2MB.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // If no errors, return true
  }, [editedProfile, newPicture]);

  const handleSubmit = useCallback(
    async (e) => {
      const oldBio = profile.bio;
      const oldInterests = profile.interests;
      e.preventDefault();
  

      // Perform validation
      if (!validateProfile()) {
        setEditedProfile((prev) => {
          return { ...prev, bio: oldBio, interests:oldInterests };
        });
        return; // If validation fails, stop submission
      }

      setIsEditing(false);

      const formData = new FormData();
      formData.append("bio", editedProfile.bio);
      formData.append("interests", editedProfile.interests);
      if (newPicture) {
        formData.append("profile_picture", newPicture);
      }

      try {
        const response = await axios.put(
          `${config.apiUrl}/profiles/update_me/`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setEditedProfile(response.data); // Update profile data
        setPreviewPicture(response.data.profile_picture); // Update preview with the saved image
        console.log("Profile updated:", response.data);
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    },
    [validateProfile, editedProfile, newPicture, token, profile.bio, profile.interests]
  );

  useEffect(() => {
    setViewingAnalysis(false);
  }, [setViewingAnalysis]);

  return (
    <div className="profile-container">
      <div className="profile-header">
        <ProfilePicture
          previewPicture={previewPicture}
          isEditing={isEditing}
          handleImageChange={handleImageChange}
          profile={profile}
          errors={errors}
        />

        <div className="profile-info">
          <h2>{username}</h2>
          <Interests
            interests={editedProfile.interests}
            handleInputChange={handleInputChange}
            isEditing={isEditing}
            errors={errors}
          />
          <p style={{ margin: "5px" }} className="profile-subtitle">
            Pro Member
          </p>
          <p className="profile-follow">3.5k followers • 1.2k following</p>
          <div className="profile-actions">
            {isEditing ? (
              <>
                <button className="btn-save" onClick={handleSubmit}>
                  Save Changes
                </button>
                <button
                  className="btn-cancel"
                  onClick={() => {
                    setIsEditing(false);
                    setPreviewPicture(profile.profile_picture); // Reset preview if canceled
                    setErrors('');
                  }}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button className="btn-edit" onClick={() => setIsEditing(true)}>
                  Edit Profile
                </button>
                <button className="btn-upgrade">Upgrade</button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="profile-tabs">
        <button className="tab active">Activity</button>
        <button className="tab">Achievements</button>
      </div>

      <div className="profile-content">
        <ProfileRole />
        <ProfileBio
          bio={editedProfile.bio}
          handleInputChange={handleInputChange}
          isEditing={isEditing}
          errors={errors.bio}
          editedProfile={editedProfile}
        />
      
        <Badges />
      </div>
    </div>
  );
}

export default Profile;
