import React from "react";
import { motion } from "framer-motion";
import "./DebateDetails.css";
// import { formatDate } from "../../data/static";
import { prettyDate } from "../../data/static";
const DebateDetails = ({ debateData, imagePreviewUrl, categories, debateFormats }) => {

  const selectedCategory = categories.find(
    (category) => category.id === debateData.category
  );
  const defaultImage =
    "https://via.placeholder.com/500x550?text=No+Image+chosen";
  const selectedFormat = debateFormats.find((debateFormat)=>debateFormat.id === debateData.format_id);
  const imageUrl =  imagePreviewUrl ||defaultImage;

  return (
    <div className="debate-details-container">
      {/* Hero Section with Debate Image */}
      {debateData.image && (
        <motion.div
          className="hero-section"
          style={{
            backgroundImage: `url(${imageUrl})`,
            borderRadius: "20px",
            backgroundSize: "cover", // Makes sure the image fills the entire width and height of the container
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents repetition
            width: "100%", // Ensure the div takes up the full width of the container
            height: "500px", // Set the height based on your requirements
          }}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="overlay">
            <motion.h1
              className="debate-title"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            >
              {debateData.title}
            </motion.h1>
          </div>
        </motion.div>
      )}

      {/* Information Cards */}
      <div className="info-cards">
        <motion.div
          className="info-card"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2>Category</h2>
          <p>{selectedCategory?.name || "not selected"}</p>
        </motion.div>

        <motion.div
          className="info-card"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2>Topic</h2>
          <p>{debateData.topic}</p>
        </motion.div>

        <motion.div
          className="info-card motion-card"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h2>Motion</h2>
          <p>{debateData.motion}</p>
        </motion.div>

        <motion.div
          className="info-card"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <h2>Format</h2>
          <span className="badge">
            {selectedFormat?.name || "Not selected"}
          </span>
        </motion.div>

        <motion.div
          className="info-card"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <h2>Mode</h2>
          <span className="badge">{debateData.mode || "not selected"}</span>
        </motion.div>

        {/* Timeline Section */}
        <motion.div
          className="info-card timeline-card"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <h2>Timeline</h2>
          <div className="timeline">
            <div className="timeline-item">
              <span className="timeline-dot"></span>
              <div className="timeline-content">
                <h3>Start Time</h3>
                <p>{prettyDate(debateData.start_time)}</p>
              </div>
            </div>
            <div className="timeline-item">
              <span className="timeline-dot"></span>
              <div className="timeline-content">
                <h3>End Time</h3>
                <p>{prettyDate(debateData.end_time)}</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default DebateDetails;
