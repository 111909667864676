import React, { useEffect, useState } from 'react'; 
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import SearchBar from './DebateListComponents/SearchBar';
import DebateCard from './DebateListComponents/DebateCard';
import DebateBoard from "./DebateListComponents/DebateBoard";
import './DebateList.css';
import { clearLocalStorage } from '../data/static';

const DebateList = ({ token }) => {
    const [debates, setDebates] = useState([]);
    const [loading, setLoading] = useState(true); // New loading state
    const navigate = useNavigate();
    localStorage.removeItem('roleTemplates');
    localStorage.removeItem("roleAssignments");
    localStorage.removeItem("userRole");

    clearLocalStorage();

    function handleNavigate(){
        navigate('/create-debate');
    }

    useEffect(() => {
        axios.get(`${config.apiUrl}/debates/`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
                if (response.data) {
                    setDebates(response.data);
                }
                setLoading(false); // Set loading to false once data is fetched
            })
        .catch(error => {
            console.error('Error fetching debates:', error);
            setLoading(false); // Set loading to false in case of error
        });
    }, [token]);

    return (
        <>
            <div className="app-container">
                <header className="header">
                    <h1>Debates</h1>
                    <button onClick={handleNavigate} className="create-debate-button">Create a debate</button>
                </header>
                <SearchBar />

                {loading ? ( // Show loading message while fetching debates
                    <div className="loading">Loading debates...</div>
                ) : (
                    <>
                        <section className="suggested-section">
                            <h2>Suggested for You</h2>
                            <div className="debate-cards-container">
                                {/* Map through the first 4 suggested debates, if available */}
                                {debates.slice(0, 4).map((debate) => (
                                    <DebateCard debate={debate} key={debate.id} />
                                ))}
                            </div>
                        </section>

                        <DebateBoard debates={debates} />
                    </>
                )}
            </div>
        </>
    );
};

export default DebateList;
