import React from "react";
import Bio from "../ProfileComponents/Bio";

const ProfileBio = ({ bio, handleInputChange, isEditing, errors, editedProfile }) => {
  return (
    <div className="bio-section">
      {isEditing ? (
        <>
          <textarea
            name="bio"
            value={bio}
            onChange={handleInputChange}
            placeholder="Your bio"
            className="bio-input-profile"
          />
          {errors && <span className="error-message">{errors}</span>}
        </>
      ) : (
          <Bio editedProfile={editedProfile} /> 
      )}
    </div>
  );
};

export default ProfileBio;
