import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import "./CreateDebate.css";
import CategorySelector from "./create-debate-components/CategorySelector";
import FormatSelector from "./create-debate-components/FormatSelector";
import TimeSelector from "./create-debate-components/TimeSelector";
import EndTimeSelector from "./create-debate-components/EndTimeSelector";
import DebateDetails from "./create-debate-components/DebateDetails";

import ProgressBar from "./ProgressBar"; // Reusable progress bar component
import "./DebateLoading.css"
import { useLocation, useNavigate } from "react-router-dom";
import SuccessDialog from './SuccessDialog.js';

const CreateDebate = ({ token }) => {
  const [categories, setCategories] = useState([]);
  const [debateFormats, setDebateFormats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorCreatingDebate, setErrorCreatingDebate] = useState("");
  const navigate = useNavigate();
  const [step, setStep] = useState(1); // Track the current step
  const totalSteps = 5; // Define total steps for progress
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [createDebateLoading, setCreateDebateLoading] = useState(false);
  const [isCreateButtonClicked, setIsCreateButtonClicked] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const location = useLocation();
  console.log("location: "+location.pathname);

  useEffect(()=>{
    if (location.pathname === "/create-debate"){
         localStorage.removeItem("selectedCategory");
         localStorage.removeItem("motion");
         localStorage.removeItem("topic");
         localStorage.removeItem("title");
         localStorage.removeItem("categoryId");
         localStorage.removeItem("selectedFormat");
         localStorage.removeItem("selectedCategory");
         setDebateData({});
    }
   
  },[location.pathname]);

  const [debateData, setDebateData] = useState({
    topic:'',
    start_time: "",
    end_time: "",
    mode: "Video",
    meeting_url: "",
    created_by: "",
    status: "Scheduled",
    category: 0,
    format_id: 1,
    title: '',
    motion:"",
    image: "",
  });

  useEffect(() => {
    if (debateData.image) {
      const objectUrl = URL.createObjectURL(debateData.image);
      setImagePreviewUrl(objectUrl);

      // Free memory when the component unmounts or when debateData.image changes
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setImagePreviewUrl(null);
    }
  }, [debateData.image]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [categoriesResponse, debateFormatsResponse] = await Promise.all([
          axios.get(`${config.apiUrl}/debate-category/`, {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token here
            },
          }),
          axios.get(`${config.apiUrl}/debate-formats/`, {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token here
            },
          }),
        ]);
        setCategories(categoriesResponse.data);
        setDebateFormats(debateFormatsResponse.data);
        setError(null);
      } catch (error) {
        setError("Failed to fetch categories or debate formats.");
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]); // Add token to the dependency array to refetch if the token changes

  const handleGenerateMotionAndImage = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiUrl}/generate-motion-image/`,
        {
          debateData,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Convert the image URL to a file
      const imageUrl = response.data.image;
      const file = await urlToFile(
        imageUrl,
        "generated-debate-image.png",
        "image/png"
      );

      setDebateData({
        ...debateData,
        motion: response.data.motion.replace(/^"(.*)"$/, "$1"),
        image: file, // Save the file instead of the URL
      });

      setLoading(false);
    } catch (error) {
      console.error("Error generating motion and image:", error);
      setLoading(false);
    }
  };

  // Utility function to convert URL to a file
  const urlToFile = async (url, filename, mimeType) => {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  };

  const handleChange = (e) => {
    setDebateData({ ...debateData, [e.target.name]: e.target.value });
    console.log(debateData);
    // save the selected title in local storage
    if(e.target.name === 'title'){
      localStorage.setItem('title',e.target.value);
      console.log("target value: "+e.target.value);
    }
    // save the selected topic in local storage
    else if(e.target.name === 'topic'){
      localStorage.setItem('topic', e.target.value);
    }
    else if(e.target.name ==='motion'){
      localStorage.setItem('motion', e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Disable the button after the first click
    if (isCreateButtonClicked) {
      return;
    }

    localStorage.removeItem("motion");
    localStorage.removeItem("topic");
    localStorage.removeItem("title");
    localStorage.removeItem("categoryId");
    localStorage.removeItem("selectedFormat");
    localStorage.removeItem("selectedCategory");

    setCreateDebateLoading(true);
    setIsCreateButtonClicked(true); // Set this to true after first click

    const formData = new FormData();

    // Append all other data
    formData.append("topic", debateData.topic);
    formData.append("start_time", debateData.start_time);
    formData.append("end_time", debateData.end_time);
    formData.append("mode", debateData.mode);
    formData.append("meeting_url", debateData.meeting_url);
    formData.append("created_by", debateData.created_by);
    formData.append("status", debateData.status);
    formData.append("category", debateData.category);
    formData.append("format_id", debateData.format_id);
    formData.append("title", debateData.title);
    formData.append("motion", debateData.motion);

    // Append the image file
    formData.append("image", debateData.image);

    try {
      const response = await axios.post(`${config.apiUrl}/debates/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Required for file uploads
        },
      });
      setCreateDebateLoading(false);
      console.log("Debate created:", response.data);
      setShowDialog(true);
    } catch (error) {
      console.error("Error creating debate:", error);
      setCreateDebateLoading(false);
      setErrorCreatingDebate("Failed to create debate.");
      setIsCreateButtonClicked(false); // Allow re-submission in case of error
    }
  };
  const handleDialogClose = ()=>{
    setShowDialog(false);
    navigate("/");
  }


  const nextStep = () => setStep((prev) => Math.min(prev + 1, totalSteps));
  const prevStep = () => setStep((prev) => Math.max(prev - 1, 1));

  return (
    <div className="debate-creation-div">
      <ProgressBar step={step} totalSteps={totalSteps} />

      <form className="debate-form" onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="four" style={{ width: "40%" }}>
            <h2>Set Debate Basics</h2>
            <CategorySelector
              updateDebateData={(categoryId) => {
                setDebateData({ ...debateData, category: categoryId });
              }}
              selectedCategory={debateData.category}
              loading={loading}
              categories={categories}
            />
            <div className="form-group">
              <label htmlFor="debateTitle">Debate Title</label>
              <input
                className="catchy-input"
                type="text"
                id="debateTitle"
                name="title"
                placeholder="Enter debate title"
                value={debateData.title}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="debateTopic">Debate Topic</label>
              <textarea
                className="professional-textarea"
                cols="100"
                rows="8"
                id="topic"
                name="topic"
                placeholder="Enter debate topic"
                value={debateData.topic}
                onChange={handleChange}
              />
            </div>
          </div>
        )}

        {step === 2 && (
          <div style={{ width: "40%" }}>
            <h2>Define Motion & Add Visuals</h2>

            {/* Generate Button */}
            <button
              type="button"
              onClick={handleGenerateMotionAndImage}
              className="submit-btn"
              disabled={loading}
            >
              {loading ? "Generating..." : "Auto Generate with AI"}
            </button>
            {/* Debate Motion Input */}
            <div className="form-group">
              <label htmlFor="debateMotion">Debate Motion</label>
              <textarea
                className="catchy-input"
                id="debateMotion"
                name="motion"
                placeholder="Enter debate motion"
                value={debateData.motion}
                onChange={handleChange}
                disabled={loading} /* Disable input while loading */
                rows="5"
                cols="50"
              />
            </div>

            {/* Debate Image Placeholder & Loading Animation */}
            <div className="form-group">
              <label htmlFor="debateImage">Debate Image</label>

              {/* Placeholder while loading */}
              {loading ? (
                <div className="image-placeholder">
                  <div className="loading-animation"></div>
                  <p className="loading-text">
                    Generating debate motion and image. Please wait, the magic
                    is happening...
                  </p>
                </div>
              ) : (
                <>
                  <label className="chooseImage-label" htmlFor="debateImage">
                    Choose Image
                    <input
                      type="file"
                      id="debateImage"
                      name="image"
                      className="chooseImage"
                      onChange={(e) =>
                        setDebateData({
                          ...debateData,
                          image: e.target.files[0],
                        })
                      }
                    />
                  </label>
                  {imagePreviewUrl && (
                    <div className="image-preview-div">
                      <img
                        src={imagePreviewUrl}
                        alt="Debate"
                        className="debate-image-preview"
                      />
                      <button
                        onClick={() => {
                          setImagePreviewUrl(null);
                          setDebateData({ ...debateData, image: null });
                        }}
                        className="delete-image-preview"
                      >
                        x
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {step === 3 && (
          <div style={{ width: "40%" }}>
            <h2>Pick Debate Format & Mode</h2>
            <FormatSelector
              updateFormat={(format) =>
                setDebateData({ ...debateData, format_id: format.id })
              }
              formats={debateFormats}
            />
            <div className="info-item">
              <h3>Debate Mode</h3>
              <p>Video.</p>
            </div>
          </div>
        )}

        {step === 4 && (
          <div style={{ width: "40%" }}>
            <h2>Schedule the Debate</h2>
            <TimeSelector
              start_time={debateData.start_time}
              handleChange={handleChange}
            />
            <EndTimeSelector
              end_time={debateData.end_time}
              handleChange={handleChange}
            />
          </div>
        )}

        {step === 5 && (
          <div style={{ width: "100%" }}>
            <h2 style={{ fontFamily: "Manrope" }}>
              Review and Confirm Details
            </h2>
            <DebateDetails
              debateData={debateData}
              imagePreviewUrl={imagePreviewUrl}
              categories={categories}
              debateFormats={debateFormats}
            />
            <button
              type="submit"
              className="submit-btn confirmandcreate"
              disabled={isCreateButtonClicked || createDebateLoading} // Disable on first click or while loading
            >
              {isCreateButtonClicked
                ? "Creating..."
                : "Confirm & Create Debate"}
            </button>
            {createDebateLoading && (
              <div className="loading-spinner-createdebate">
                <div className="spinner-createdebate"></div>
              </div>
            )}
          </div>
        )}

        <div className="navigation-buttons">
          {step > 1 && (
            <button type="button" onClick={prevStep}>
              {" "}
              Back
            </button>
          )}
          {step < 2 && <button onClick={()=>{navigate('/dashboard')}} type="button">Cancel</button>}
          {step < totalSteps && (
            <button type="button" onClick={nextStep}>
              Next
            </button>
          )}
        </div>
        {errorCreatingDebate && (
          <p style={{ color: "red" }}>{errorCreatingDebate}</p>
        )}
        {!errorCreatingDebate && showDialog && !createDebateLoading && (
          <SuccessDialog
            message="Your debate has been successfully created!"
            welcome="Get ready to engage in insightful discussions and challenge perspectives!"
            buttonText="Return to home"
            isOpen={showDialog}
            onClose={handleDialogClose}
          />
        )}
      </form>
    </div>
  );
};

export default CreateDebate;
