import React from 'react'

const Bio = ({editedProfile}) => {
  return (
    <div className="profile-bio">
      <h3>Biography</h3>
      {editedProfile.bio ? editedProfile.bio : <p>No Bio is available</p>}
      <p></p>
    </div>
  );
}

export default Bio