import React from "react";
import { defaultProfilePicture } from "../../data/static";
const ProfilePicture = ({
  previewPicture,
  isEditing,
  handleImageChange,
  profile,
  errors,
}) => {
  return (
    <div className="profile-pic-section">
      <div className="profile-pic-wrapper">
        {previewPicture ? (
          <img src={previewPicture} alt="" className="profile-pic" />
        ) : (
          <img src={defaultProfilePicture} alt="" className="profile-pic" />
        )}

        {isEditing && (
          <div className="upload-overlay">
            <label htmlFor="profile-pic-upload" className="upload-label">
              Change Profile Picture
            </label>
            <input
              type="file"
              id="profile-pic-upload"
              accept="image/*"
              onChange={handleImageChange}
              className="upload-input"
            />
            {errors.profile_picture && (
              <span className="error-message">{errors.profile_picture}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePicture;
